@import '../../../../theme/theme';

.publicProfileLayout {
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'profileContainer'
    'mapContainer'
    'progressContainer'
    'communityContributionsContainer'
    'myContributionsContainer'
    'infoAndCtaContainer';
  gap: 16px;

  &.noProgress {
    grid-template-areas:
      'profileContainer'
      'mapContainer'
      'communityContributionsContainer'
      'myContributionsContainer'
      'infoAndCtaContainer';
  }

  &.noLeaderboard {
    grid-template-areas:
      'profileContainer'
      'mapContainer'
      'progressContainer'
      'myContributionsContainer'
      'infoAndCtaContainer';

    &.noProgress {
      grid-template-areas:
        'profileContainer'
        'mapContainer'
        'myContributionsContainer'
        'infoAndCtaContainer';
    }
  }

  &.tpoProfile {
    grid-template-areas:
      'profileContainer'
      'tpoProjectsContainer'
      'infoAndCtaContainer';
  }

  > section {
    border-radius: 16px;
    background-color: $backgroundColor;
    margin: 0 20px;
    overflow: hidden;
  }

  // heights are temporary values and may not be needed
  .profileContainer {
    grid-area: profileContainer;
  }

  .mapContainer {
    margin: 0;
    border-radius: 0;
    border: none;
    min-height: 435px;
    grid-area: mapContainer;
  }

  .progressContainer {
    padding: 16px;
    grid-area: progressContainer;

    &.loading {
      padding: 0;
    }
  }

  .myContributionsContainer {
    grid-area: myContributionsContainer;
    border: 4px solid #fff;

    &.loading {
      border: none;
    }
  }

  .communityContributionsContainer {
    grid-area: communityContributionsContainer;
    border: 4px solid #fff;

    &.loading {
      border: none;
    }
  }

  .infoAndCtaContainer {
    grid-area: infoAndCtaContainer;
  }

  .tpoProjectsContainer {
    grid-area: tpoProjectsContainer;
    background-color: transparent;
    border-radius: 0;
  }
}

@include smTabletView {
  .publicProfileLayout {
    > section {
      margin: 0;
    }

    .mapContainer {
      border-radius: 16px;
      border: 4px solid $backgroundColor;

      &.loading {
        border: none;
      }
    }
  }
}

@include mdTabletView {
  .publicProfileLayout {
    grid-template-columns: 19fr 21fr;
    grid-template-areas:
      'profileContainer mapContainer'
      'progressContainer progressContainer'
      'communityContributionsContainer communityContributionsContainer'
      'myContributionsContainer myContributionsContainer'
      'infoAndCtaContainer infoAndCtaContainer';

    &.noProgress {
      grid-template-areas:
        'profileContainer mapContainer'
        'communityContributionsContainer communityContributionsContainer'
        'myContributionsContainer myContributionsContainer'
        'infoAndCtaContainer infoAndCtaContainer';
    }

    &.noLeaderboard {
      grid-template-areas:
        'profileContainer mapContainer'
        'progressContainer progressContainer'
        'myContributionsContainer myContributionsContainer'
        'infoAndCtaContainer infoAndCtaContainer';

      &.noProgress {
        grid-template-areas:
          'profileContainer mapContainer'
          'myContributionsContainer myContributionsContainer'
          'infoAndCtaContainer infoAndCtaContainer';
      }
    }

    &.tpoProfile {
      grid-template-areas:
        'profileContainer .'
        'tpoProjectsContainer tpoProjectsContainer'
        'infoAndCtaContainer infoAndCtaContainer';
    }

    > section {
      border-radius: 24px;
    }

    .mapContainer {
      min-height: 450px;
      border-radius: 24px;
    }

    .profileContainer,
    .progressContainer,
    .myContributionsContainer,
    .communityContributionsContainer,
    .infoAndCtaContainer {
      height: auto;
    }
  }
}

@include lgLaptopView {
  .publicProfileLayout {
    grid-template-columns: 3fr 1fr 2fr 2fr;
    grid-template-areas:
      'profileContainer mapContainer mapContainer mapContainer'
      'progressContainer progressContainer progressContainer progressContainer'
      'myContributionsContainer myContributionsContainer communityContributionsContainer communityContributionsContainer'
      'infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer';

    &.noProgress {
      grid-template-areas:
        'profileContainer mapContainer mapContainer mapContainer'
        'myContributionsContainer myContributionsContainer communityContributionsContainer communityContributionsContainer'
        'infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer';
    }

    &.noLeaderboard {
      grid-template-areas:
        'profileContainer mapContainer mapContainer mapContainer'
        'progressContainer progressContainer progressContainer progressContainer'
        'myContributionsContainer myContributionsContainer myContributionsContainer myContributionsContainer'
        'infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer';

      &.noProgress {
        grid-template-areas:
          'profileContainer mapContainer mapContainer mapContainer'
          'myContributionsContainer myContributionsContainer myContributionsContainer myContributionsContainer'
          'infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer';
      }
    }

    &.tpoProfile {
      grid-template-areas:
        'profileContainer profileContainer . .'
        'tpoProjectsContainer tpoProjectsContainer tpoProjectsContainer tpoProjectsContainer'
        'infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer infoAndCtaContainer';
    }
  }
}

@include xsPhoneView {
  .publicProfileLayout {
    width: fit-content;
  }
}
