.infoAndCtaContainer {
    display: flex;
    padding: 40px;
    gap: 40px;
    flex-direction: column;
}

.publicProfileActionContainer, .SDGCardListContainer {
    width: 100%;
}

.publicProfileActionContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.singleSDGCardContainer {
    display: flex;
    flex-direction: column;
    color: #FFF;   
    gap: 2.37px; 
    padding: 8.2px 15.1px;
    align-items: center;
    justify-content: space-between;    
    text-align: center;

    img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
}

.singleCardIndex {
    font-weight: 700;
    font-size: 16.72px;

    @media screen and (max-width: 620px) {
        font-size: 22px;
    }

    @media screen and (max-width: 380px) {
       font-size: 24px;
    }
}

.singleCardTitle {
    font-size: 10px;
    margin-bottom: 4px;
    text-transform: uppercase;

    @media screen and (max-width: 620px) {
        font-size: 16px;
    }
    @media screen and (max-width: 380px) {
        font-size: 18px;
     }
}

.singleCardIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding-bottom: 10px;
    max-height: 48px;
    max-width: 48px;
    svg {
       width: 100%;
       height: 100%;
    }

    @media screen and (max-width: 380px) {
        max-height: 60px;
        max-width: 60px;
     }
}

.publicProfileActionsHeader {
    display: flex;
    justify-content: space-between;

    .headerTitle {
        font-size: 22px;
        font-weight: 600;
    }
}

.publicProfileActionCardsContainer {
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: center;    
}

.singleProfileActionCardContainer {
    flex:1;
    img {
        width: 100%;
        transition: transform 0.2s ease-in-out;
        min-height: 107px;
    }
    img:hover {
        transform: scale(1.02);
    }
}

.profileCardTextContainer {
    max-width: 250px;
    padding: 0 18px;

}

.singleProfileActionCardTitle {
    font-size: 18px;
    font-weight: 700;
    color: #333;

    @media screen and (max-width: 900px) {
        font-size: 16px;
    } 
}

.singleProfileActionCardText {
    font-size: 14px;
    color: #333;

    @media screen and (max-width: 900px) {
        font-size: 12px;
    } 
}

@media screen and (max-width: 700px) {

    .publicProfileActionCardsContainer {
        flex-direction: column;
        gap: 10px;
    }

    .publicProfileActionsHeader {
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        .headerTitle {
            text-align: center;
        }
    }

    .profileCardTextContainer {
        max-width: 100%;
    }

    .singleProfileActionCardTitle {
        font-size: 20px;
        max-width: 100%;
        margin-top: 10px;
    }

    .singleProfileActionCardText {
        font-size: 18px;
        max-width: 100%;
    }
} 